<template>
  <PublicProfileBuilderBody ref="body" />
</template>

<script>
import PageMixin from '@/components/mixins/Page'
import PublicProfileBuilderBody from '@/components/bodies/PublicProfileBuilder.vue'

export default {
  mixins: [PageMixin],
  name: 'PublicProfileBuilder',
  data() {
    return {
      loading: 0,
      isDirty: 0
    }
  },
  methods: {},
  components: {
    PublicProfileBuilderBody
  }
}
</script>
